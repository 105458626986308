import React, { useMemo } from 'react'
import isFinite from 'lodash/isFinite'
import { When } from 'react-if'
import { ParagraphSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

interface WatchProgressStatusProps {
  position?: number
  duration?: number
}

const useProgressStyle = (position: number | undefined, duration: number | undefined) => {
  const progressPercent = position && duration ? (position / duration) * 100 : 0
  return { width: `${progressPercent}%` }
}

export const WatchProgressStatus: React.FC<WatchProgressStatusProps> = ({ position, duration }) => {
  const { t } = useTranslate('watch')

  const progressStyle = useProgressStyle(position, duration)
  const currentMinuteMark = useMemo(() => (position ? Math.floor(position / 60) : 0), [position])
  const totalMinutes = useMemo(() => (duration ? Math.floor(duration / 60) : 0), [duration])
  const minutesRemaining = totalMinutes - currentMinuteMark

  return (
    <When condition={position && duration}>
      <div className="mb-6 flex w-full items-center">
        <div className="relative mr-4 h-2 grow-1 overflow-hidden rounded-lg bg-lightened-200">
          <div className="absolute inset-y-0 left-0 bg-oxide" style={progressStyle} />
        </div>
        <ParagraphSM className="mr-8 text-[11px] leading-4" weight="normal" color="core-gray-300">
          {isFinite(currentMinuteMark) && isFinite(totalMinutes) && currentMinuteMark < totalMinutes
            ? t('countMinutesRemaining', {
                count: minutesRemaining,
                defaultValue_one: '{{count}} min remaining',
                defaultValue_other: '{{count}} mins remaining',
              })
            : null}
        </ParagraphSM>
      </div>
    </When>
  )
}
