import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import { Case, Default, Switch, When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { WatchTriangleIcon } from '@/atoms/Icons/WatchTriangleIcon'
import { IconColor } from '@/atoms/utils'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { useGuildEarlyAccessModalContext } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModal'
import { useBotDetection } from '@/services/BotDetection'
import { useGuildUser } from '@/services/GuildUserService'
import { useUser } from '@/services/UserService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface WatchHeroCTAProps {
  ctaPath?: string
  ctaText?: string
  ctaClassName?: string
  isGuildMemberContent?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => void
  position?: number
  duration?: number
  iconColor?: IconColor
}

const useProgressStyle = (position: number | undefined, duration: number | undefined) => {
  const progressPercent = position && duration ? (position / duration) * 100 : 0
  return { width: `${progressPercent}%` }
}

export const WatchHeroCTA: React.FC<WatchHeroCTAProps> = ({
  ctaPath,
  ctaText,
  ctaClassName,
  isGuildMemberContent,
  onClick,
  position,
  duration,
  iconColor = 'gray-950',
}) => {
  const progressStyle = useProgressStyle(position, duration)
  const { overrideTheaterWatchButtonBuyTicketsUrl, overrideTheaterWatchButtonTrailerUrl } = useProjectMeta()
  const { t } = useTranslate('watch')
  const { isLoggedIn } = useUser()
  const { isGuildMember } = useGuildUser()
  const { isBot } = useBotDetection()
  const modalContext = useGuildEarlyAccessModalContext()
  const showOriginal = isLoggedIn || isBot

  return (
    <When
      condition={
        isGuildMemberContent ||
        ctaPath ||
        overrideTheaterWatchButtonTrailerUrl ||
        overrideTheaterWatchButtonBuyTicketsUrl
      }
    >
      <Switch>
        <Case condition={isGuildMemberContent && !isGuildMember && !ctaPath}>
          <Button
            id="watch-now" // leaving this only because it might be used as a link for marketing?
            className={classNames('py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4', ctaClassName)}
            onClick={modalContext?.openModal}
            variant="white"
          >
            <div className="relative flex w-full flex-row items-center justify-center">
              <WatchTriangleIcon size={16} className="mr-2" color={iconColor} />
              <div>{t('joinToWatch', 'Join To Watch')}</div>
              <div />
            </div>
          </Button>
        </Case>
        <Case condition={ctaPath}>
          <LinkButton
            id="watch-now" // leaving this only because it might be used as a link for marketing?
            className={classNames(
              'py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4',
              {
                'flex flex-col gap-1 !max-h-[68px]': position && duration,
              },
              ctaClassName,
            )}
            href={ctaPath as string}
            onClick={onClick}
            variant="white"
          >
            <div className="flex items-center">
              <WatchTriangleIcon size={16} className="mr-2" color={iconColor} />
              <div className="max-w-[20ch] overflow-hidden text-ellipsis text-nowrap">{ctaText}</div>
            </div>
            <When condition={position && duration}>
              <div className="flex w-full items-center">
                <div className="relative h-1 grow-1 rounded-full bg-shaded-150">
                  <div className="absolute inset-y-0 left-0 rounded-full bg-core-gray-950" style={progressStyle} />
                </div>
              </div>
            </When>
          </LinkButton>
        </Case>
        <Case condition={overrideTheaterWatchButtonBuyTicketsUrl}>
          <LinkButton
            id="watch-now" // leaving this only because it might be used as a link for marketing?
            className={classNames('py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4', ctaClassName)}
            href={overrideTheaterWatchButtonBuyTicketsUrl as string}
            variant="white"
          >
            <div className="relative flex w-full flex-row items-center justify-center">
              <TicketIcon color="black" size={16} className="mr-2" />
              <div>
                <Translate t={t} i18nKey="getTheatricalTickets">
                  Get Theatrical Tickets
                </Translate>
              </div>
              <div />
            </div>
          </LinkButton>
        </Case>
        <Case condition={overrideTheaterWatchButtonTrailerUrl}>
          <LinkButton
            id="watch-now" // leaving this only because it might be used as a link for marketing?
            className={classNames('py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4', ctaClassName)}
            href={overrideTheaterWatchButtonTrailerUrl as string}
            variant="white"
          >
            <div className="relative flex w-full flex-row items-center justify-center">
              <WatchTriangleIcon size={16} className="mr-2" color={iconColor} />
              <div>
                <Translate t={t} i18nKey="watchTheTrailer">
                  Watch the Trailer
                </Translate>
              </div>
              <div />
            </div>
          </LinkButton>
        </Case>
        <Case condition={ctaPath}>
          <Switch>
            <Case condition={showOriginal}>
              <LinkButton
                id="watch-now" // leaving this only because it might be used as a link for marketing?
                className={classNames('py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4', ctaClassName)}
                href={ctaPath as string}
                variant="white"
              >
                <div className="relative flex w-full flex-row items-center justify-center">
                  <WatchTriangleIcon size={16} className="mr-2" color={iconColor} />
                  <div>{ctaText}</div>
                  <div />
                </div>
              </LinkButton>
            </Case>
            <Default>
              <LinkButton
                id="watch-now" // leaving this only because it might be used as a link for marketing?
                className={classNames('py-3 px-6 mb-4 md:mb-0 md:w-fit md:mr-4', ctaClassName)}
                href={ctaPath as string}
                variant="white"
              >
                <div className="relative flex w-full flex-row items-center justify-center">
                  <WatchTriangleIcon size={16} className="mr-2" color={iconColor} />
                  <div>{ctaText}</div>
                  <div />
                </div>
              </LinkButton>
            </Default>
          </Switch>
        </Case>
      </Switch>
    </When>
  )
}
